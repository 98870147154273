<template>
  <div>
    <Modal v-model="show" width="80%">
      <p slot="header" class="text-center">查看产品</p>
      <div>

        <Row :gutter="8" class="m-b-5">
          <i-col span="3">
            <Select v-model="query.type" :clearable="true" size="small" placeholder="选择媒体类型"
              @on-change="handleChangeType">
              <Option v-for="item in typeArray" :key="'type_' + item.value" :value="item.value">{{ item.name }}</Option>
            </Select>
          </i-col>
          <i-col span="3" v-show="query.type === 1">
            <Select v-model="query.categoryId" :clearable="true" size="small" placeholder="选择类目"
              @on-change="handleChangeCategory">
              <Option v-for="item in categoryArray" :key="'category_' + item.id" :value="item.id">{{ item.name }}
              </Option>
            </Select>
          </i-col>
          <i-col span="3">
            <Select v-model="query.productId" :clearable="true" size="small" placeholder="选择产品">
              <Option v-for="item in productArray" :key="'productId_' + item.id" :value="item.id">{{ item.name }}
              </Option>
            </Select>
          </i-col>
          <i-col span="2">
            <Select v-model="query.enabled" :clearable="true" size="small" placeholder="是否可用">
              <Option v-for="item in enabledArray" :key="'enabled_' + item.value" :value="item.value">{{ item.name }}
              </Option>
            </Select>
          </i-col>
          <i-col span="4">
            <i-input v-model="query.keyword" size="small" placeholder="输入关键字"></i-input>
          </i-col>
          <i-col span="5">
            <i-button size="small" class="m-r-5" type="primary" icon="ios-search" @click="handleSearch">搜索</i-button>
            <!-- <Button type="success" size="small" @click="onSetSku">分配产品</Button> -->
          </i-col>
        </Row>
        <div class="m-b-5">
          <Button type="error" size="small" :disabled="removeDisabled" @click="onRemoveSku">批量移除</Button>
        </div>
        <Table size="small" stripe :data="list" :columns="tableColumns" :loading="tableLoading" :max-height="700"
          @on-selection-change="onSelection"></Table>
        <div class="paging_style">
          <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator
            :current="query.pageNumber" @on-change="handlePageChange"></Page>
        </div>
      </div>
    </Modal>
    <!-- <set-product v-if="setSku" ref="setProduct" :projectId="projectId" :companyId="companyId"
      :onSuccess="getTableData" /> -->
  </div>
</template>

<script>
import { projectProductMxins } from '../../mixins/product'
import { getCompanySku, removeCompanySku } from '@/api/product/productskuedit'
// import SetProduct from './SetProduct.vue'
export default {
  props: {
    companyId: {
      type: Number,
      required: true
    },
    projectId: {
      type: Number,
      required: true
    }
  },
  mixins: [projectProductMxins],
  // components: {
  //   SetProduct
  // },
  data () {
    return {
      show: false,
      query: {
        pageNumber: 1,
        pageSize: 15,
        categoryId: null,
        companyId: null,
        enabled: 1,
        productId: null,
        projectId: null,
        type: undefined,
        keyword: ''
      },
      selectedItems: [],
      setSku: false
    }
  },
  computed: {
    removeDisabled () {
      return this.selectedItems.length === 0
    }
  },
  methods: {
    showModal () {
      this.selectedItems = []
      this.query.companyId = this.companyId
      this.query.projectId = this.projectId
      this.getTableColumns(this.query.type)
      this.getTableData()
      this.show = true
    },
    async getTableData () {
      this.tableLoading = true
      const result = await getCompanySku(this.query).finally(() => { this.tableLoading = false })
      if (result && !result.errcode) {
        this.list = result.list
        this.total = result.totalRow
      } else {
        this.list = []
        this.total = 0
      }
    },
    handlePageChange (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.getTableData()
    },
    handleChangeType () {
      this.getTableColumns(this.query.type)
      this.query.categoryId = null
      this.query.pageNumber = 1
      this.getCategoryData()
      if (this.query.type === 1) {
        this.productArray = []
      }
      this.getTableData()
    },
    onSelection (selections) {
      this.selectedItems = selections.map(x => x.id)
    },
    // onSetSku () {
    //   this.setSku = true
    //   this.$nextTick(() => {
    //     this.$refs.setProduct.showModal()
    //   })
    // },
    onRemoveSku () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认要移除所选项？',
        onOk: async () => {
          const postData = {
            companyId: this.companyId,
            skuIds: JSON.stringify(this.selectedItems)
          }
          const result = await removeCompanySku(postData)
          if (result && result.success) {
            this.selectedItems = []
            this.$Notice.success({ desc: '操作成功' })
            this.getTableData()
          }
        }
      })
    }
  }
}
</script>
